import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { ActivityColumnKeys } from '../models/activity-column.enum';
import { ProcessingActivitiesQuery } from '../state/processing-activities.query';
import { ProcessingActivity } from 'src/app/api/models/processing-activities/processing-activities.interface';
import { ProcessActivityStatusEnum } from 'src/app/api/models/processing-activities/processing-activities.enum';
import { CustomFieldsQuery } from 'src/app/company-settings/state/custom-fields/custom-fields.query';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { FieldInputType } from 'src/app/api/models/company-settings/custom-fields.enum';
import { ActivityFieldIdToNamePipe } from '../pipes/activity-field-id-to-name.pipe';
//import { DataSubjectIdToNamePipe } from '../pipes/data-subject-id-to-name.pipe';

@Injectable()
export class ProcessingActivityTableCsvHelper {

    constructor(
        private datePipe: DatePipe,
        private contentPipe: ContentPipe,
        //private dataSubjectIdToNamePipe: DataSubjectIdToNamePipe,
        private activityFieldIdToNamePipe: ActivityFieldIdToNamePipe,
        private processingActivitiesQuery: ProcessingActivitiesQuery,
        private customFieldsQuery: CustomFieldsQuery,
    ) {}

    getValueByKey(activity: ProcessingActivity, key: ActivityColumnKeys | string): string {
      const values: Record<ActivityColumnKeys, string> = {
        //"Data subject": activity.dataSubjectCategories?.length ? this.dataSubjectIdToNamePipe.transform(activity.dataSubjectCategories) : '-',
        "Data subject": '-',
        "Last DPIA": (activity.dpiaInfo?.lastUpdated && activity.dpiaInfo?.dpiaEnabled) ? this.datePipe.transform(activity.dpiaInfo?.lastUpdated, 'MMM, yyyy') : '-',
        "Data sources": `${Object.keys(activity.systems).length}`,
        "Data types": this.getDataTypes(activity),
        Status: this.getStatus(activity),
        Name: activity.name,
        Functions: activity.functions?.length ? activity.functions.map(fn => this.activityFieldIdToNamePipe.transform(fn, CustomValueTypeEnum.BusinessUnit)).join(', ') : '-',
      };

      if (key in values) {
        return values[key];
      }
      else if (activity.customActivityFields) {
        let customActivityField = activity.customActivityFields.find(field => field.id === key);
        if (!!customActivityField) {
          const field = this.customFieldsQuery.getEntity(customActivityField?.id);
          if (!!field && field?.inputType === FieldInputType.Select) {
            return customActivityField.values.optionIds.length > 0 ? customActivityField.values.optionIds.map(id => field.inputData.inputOptions.find(f => f.id === id).name).join(', ') : '-';
          } else {
            return customActivityField.values.text ?? '-';
          }
        } else {
          return customActivityField?.value ? customActivityField.value : '-';
        }
      }

      return '-';
    }

    private getDataTypes(activity: ProcessingActivity): string {
      const processingActivitiesDataTypes = this.processingActivitiesQuery.getDataTypes();
      return '' + processingActivitiesDataTypes.get(activity.name)?.length || '-';
    }

    private getStatus(activity: ProcessingActivity): string {
      switch (activity.status) {
        case ProcessActivityStatusEnum.Draft:
          return this.contentPipe.transform('ropa.statusDraft');

        case ProcessActivityStatusEnum.Operational:
          return this.contentPipe.transform('ropa.statusOperational');

        default:
          return '-';
      }
    }
}