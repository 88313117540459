import { computed, Inject, Injectable, signal, Signal } from '@angular/core';
import { filter, map, tap, Observable, switchMap, combineLatest, firstValueFrom, from, finalize, of, catchError, EMPTY, throwError, BehaviorSubject } from 'rxjs';
import { RxActions } from '@rx-angular/state/actions';
import { DatePipe } from '@angular/common';
import { DialogRef } from '@ngneat/dialog';
 
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';
import { IsDateInPastPipe } from "../../shared/pipes/is-date-in-past.pipe";
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { SystemInstance } from 'src/app/systems/models/systems.interface';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { CamelCaseWithSpacesPipe } from 'src/app/shared/pipes/camel-case-with-spaces.pipe';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';

import { AiAssessmentsStore } from '../state/ai-assessments.store';
import { AiAssessmentEntityTypeEnum } from 'src/app/api/models/ai-assessments/ai-assessments.enum';
import { AiAssessmentActions, AiAssessmentActionUpdateState } from '../models/ai-assessment-actions.interface';
import { AiAssessmentExportMetadata, AiAssessmentExportTemplateInstance } from '../models/ai-assessment-export.interface';
import { AiAssessmentAttachment, AiAssessmentEntity, AiAssessmentInstance, AiAssessmentLockStatus, AiAssessmentPageRequest, AssessmentTemplateRequest, AssessmentTemplateResponse } from 'src/app/api/models/ai-assessments/ai-assessments.interface';
import { AiAssessmentCollaborationState, AiAssessmentsColumnEnum, AiAssessmentsFilteringGroupEnum, AiAssessmentsFormControlTypeEnum, AiAssessmentStatusEnum, AiAssessmentTemplateEnum } from '../models/ai-assessments.enum'; 
import { AiAssessmentsFormControl, AiAssessmentsMineOSTemplate, AiAssessmentTableStatus } from '../models/ai-assessments.interface';
import { AiAssessmentsV2ParserService } from './ai-assessments-v2-parser.service';
import { AiAssessmentsExportHelper } from './ai-assessments-export-helper';

import { CustomFieldsQuery } from 'src/app/company-settings/state/custom-fields/custom-fields.query';
import { ProcessingActivityPartial } from 'src/app/api/models/processing-activities/processing-activities.interface';
import { CompanySettingsQuery } from 'src/app/company-settings/state/company-settings.query';
import { PdfGeneratorService } from 'src/app/pdf-generator/pdf-generator.service';
import { ExportTypeEnum } from 'src/app/pdf-generator/pdf-generator.interface';
import { ContentSchemaHelper } from 'src/app/services/content/content-schema-helper';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { DomSanitizer } from '@angular/platform-browser';
import { TableCustomView } from 'src/app/api/models/custom-views/custom-views.interface';
import { DialogsManagerService } from 'src/app/services/dialogs-manager.service';
import { MineAsyncOperationSpinnerDialogComponent } from 'src/app/shared/mine-async-operation-spinner-dialog/mine-async-operation-spinner-dialog';
import { LoggerService } from 'src/app/logger/logger.service';
import { MineSnackbarService } from 'src/app/shared/mine-snackbar/mine-snackbar.service';
import { MineSnackbarType } from 'src/app/shared/mine-snackbar/mine-snackbar-type';
import { Risk } from 'src/app/api/models/risks/risks.interface';
import { CustomFieldOption, CustomFieldV2 } from 'src/app/api/models/company-settings/custom-fields.interface';
import { RandomUUIDPipe } from 'src/app/shared/pipes/random-uuid.pipe';
import { API_CLIENT_ASSESSMENTS_SERVICE } from 'src/app/api/injectors/api-client-ai-assessments.token';
import { BaseApiClientAiAssessmentsService } from 'src/app/api/base-api-client-ai-assessments.service';
import { AiAssessmentsLockStatusService } from './ai-assessments-lock-status.service';
import { AssessmentsLogicManagerService } from './ai-assessments-logic-manager.service';

import * as he from 'he';

type StatefulConditionFunction = (
  control: AiAssessmentsFormControl,
  state: any
) => [boolean, any];

export type entityId = string | number;
​
@Injectable({
     providedIn: 'root',
})
export class AiAssessmentsService {
    readonly CROSS_FUNCTIONAL_FUNCTION_NAME = "Cross functional";
	private functions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	functions$ = this.functions.asObservable();

    readonly DEFAULT_TEMPLATE = {
        type: 'AI',
        name: 'AI Assessment',
    };
    
    private readonly FORM_TEMPLATES = this.contentPipe.transform('ai-assessments.templates') as AiAssessmentsMineOSTemplate[];

    private styleElement: HTMLStyleElement; // Store a reference to the added style element (in case selected template comes with style)

    private dialogRef: DialogRef<MineAsyncOperationSpinnerDialogComponent>;

    private readonly loggerName = 'AiAssessmentsService';

    private devAssessmentTemplateSavedQuestionsFF: boolean;

    private devAssessmentsApiV2FF: boolean;

    private randomUUIDPipe = new RandomUUIDPipe();

    constructor(
        private store: AiAssessmentsStore,
        private systemsQuery: SystemsQuery,
        private profileQuery: ProfileQuery,
        private featureFlagQuery: FeatureFlagQuery,
        private customValuesQuery: CustomValuesQuery,
        private customFieldsQuery: CustomFieldsQuery,
        private companySettingsQuery: CompanySettingsQuery,
        private assessmentsLogicManagerService: AssessmentsLogicManagerService,
        private aiAssessmentsV2ParserService: AiAssessmentsV2ParserService,
        private aiAssessmentsExportHelper: AiAssessmentsExportHelper,
        private pdfGeneratorService: PdfGeneratorService,
        private camelCaseWithSpacesPipe: CamelCaseWithSpacesPipe,
        private isDateInPastPipe: IsDateInPastPipe,
        private htmlDecodePipe: HtmlDecodePipe,
        private sanitizer: DomSanitizer,
        private contentPipe: ContentPipe,
        private datePipe: DatePipe,
        private dialogService: DialogsManagerService,
        private loggerService: LoggerService,
        private snackbarService: MineSnackbarService,
        private aiAssessmentsLockStatusService: AiAssessmentsLockStatusService,
        @Inject(API_CLIENT_ASSESSMENTS_SERVICE) private apiClientAiAssessmentsService: BaseApiClientAiAssessmentsService,
    ) {
        const functions = Object.assign([], this.contentPipe.transform('ai-assessments.functions'));
        this.setFunctions(functions);
        this.initFeatureFlags();
    }

    initFeatureFlags(): void {
        const flags = this.featureFlagQuery.getMultipleFlags([
            FeatureFlags.DevAssessmentTemplateSavedQuestions,
            FeatureFlags.DevAssessmentsApiV2,
        ]);

        this.devAssessmentTemplateSavedQuestionsFF = flags.devAssessmentTemplateSavedQuestions;
        this.devAssessmentsApiV2FF = flags.devAssessmentsApiV2;
    }

    getEmpty(): Signal<boolean | undefined> {
        return this.store.signal('empty');
    }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }
        
    setDisabled(disabled: boolean): void {
        this.store.set({ 'disabled': disabled });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }
        
    selectLoading(): Observable<boolean> {
        return this.store.select('loading');
    }
        
    getDisabled(): Signal<boolean | undefined> {
        return this.store.signal('disabled');
    }
        
    selectDisabled(): Observable<boolean> {
        return this.store.select('disabled');
    }

    async getSystemsByAssessmentId(assessmentId: string): Promise<SystemInstance[]> {
        const assessment = await this.selectAssessmentById(assessmentId);

        const sytemsIds = assessment.relatedEntities
            ?.filter(re => re.type === AiAssessmentEntityTypeEnum.System)?.map(re => re.id);

        return this.systemsQuery.getSystemsByIds(sytemsIds);
    }

    getAssessments(asArray: boolean = false): Signal<Map<entityId, AiAssessmentInstance> | AiAssessmentInstance[]> {
        if (asArray) {
            return signal(Array.from(new Set([...this.store.get('assessments').values()])));
        }
        return this.store.signal('assessments');
    }

    getAssessmentById(id: entityId): Signal<AiAssessmentInstance> {
        return computed(() => {
            const assessments = this.getAssessments(true)() as AiAssessmentInstance[];
            return assessments.find(assessment => assessment.id === id);
        });
    }

    getActivitiesBySystemId(systemId: string): Signal<ProcessingActivityPartial[]> {
        return computed(() => {
            const paAssessments = this.getPaAssessments()();
      
            return paAssessments
              .filter(assessment => 
                assessment.relatedEntities?.some(entity => 
                  entity.type === AiAssessmentEntityTypeEnum.System && entity.id === systemId
                )
              )
              .map(assessment => this.mapPaAssessmentToProcessingActivity(assessment));
        });
    }

    getPaAssessments(): Signal<AiAssessmentInstance[]> {
        return computed(() => {
            const paAssessments: AiAssessmentInstance[] = [];
            const assessments = this.store.signal('assessments')();
            assessments?.forEach(assessment => {
                if (assessment.templateType === AiAssessmentTemplateEnum.PA) {
                    paAssessments.push(assessment);
                }
            });
            return paAssessments;
        });
    }

    getPaAssessment(id: entityId): Signal<AiAssessmentInstance> {
        return computed(() => {
            const paAssessments = this.getPaAssessments()();
            return paAssessments.find(assessment => assessment.id === id);
        });
    }

    getPaAssessmentsAsProcessingActivities(): Signal<ProcessingActivityPartial[]> {
        return computed(() => {
            return this.getPaAssessments()().map(assessment => 
                this.mapPaAssessmentToProcessingActivity(assessment));
        });
    }

    getPaAssessmentAsProcessingActivity(id: entityId): Signal<ProcessingActivityPartial> {
        return computed(() => {
            const activities = this.getPaAssessmentsAsProcessingActivities()();
            return activities.find(activity => activity.id === id);
        });
    }

    selectSystemsWithPaAssessmentsMap(): Signal<Map<entityId, ProcessingActivityPartial[]>> {
        return computed(() => { 
            const processingActivities = this.getPaAssessments()();
            return this.getSystemsWithPaAssessmentsMap(processingActivities);
        });
    }

    private getSystemsWithPaAssessmentsMap(paAssessments: AiAssessmentInstance[]): Map<entityId, ProcessingActivityPartial[]> {
        const systemsWithPa = new Map<entityId, ProcessingActivityPartial[]>();

        paAssessments?.forEach(paAssessment => {
            const relatedSystems = paAssessment.relatedEntities
                ?.filter(entity => entity.type === AiAssessmentEntityTypeEnum.System);
                
            if (relatedSystems.length) { 
                const pa: ProcessingActivityPartial = 
                    this.mapPaAssessmentToProcessingActivity(paAssessment);

                relatedSystems.forEach(s => {
                    const tempPaArray = systemsWithPa.get(s.id);
                    if (!!tempPaArray) {
                        const newArray = [...tempPaArray, paAssessment];
                        systemsWithPa.set(s.id, <ProcessingActivityPartial[]>newArray);
                    }
                    else {
                        systemsWithPa.set(s.id, <ProcessingActivityPartial[]>[pa]);
                    }
                });
            }
        });

        return systemsWithPa;
    }

    mapPaAssessmentToProcessingActivity(paAssessment: AiAssessmentInstance, suggestionId?: string): ProcessingActivityPartial {
        const systems = paAssessment.relatedEntities
            ?.filter(re => re.type === AiAssessmentEntityTypeEnum.System)
            ?.reduce((acc, curr) => {
               return  acc[curr.id] = {
                ...curr
               };
            }, {});

        const dataSubjectCategories = paAssessment.relatedEntities
            .filter(re => re.type === AiAssessmentEntityTypeEnum.DataSubject).map(dataSubject => dataSubject.id);
        
        return {
            id: suggestionId ?? paAssessment.id,
            name: paAssessment.name,
            dataSubjectCategories,
            systems
        };
    }
        
    selectAssessments(asArray: boolean = false): Observable<Map<entityId, AiAssessmentInstance> | AiAssessmentInstance[]> {
        if (asArray) {
            return this.store.select('assessments').pipe(
                map(assessments => Array.from(new Set([...assessments.values()])))
            );
        }
        return this.store.select('assessments');
    }

    selectAssessmentsWithRisks(asArray: boolean = false): Observable<Map<entityId, AiAssessmentInstance> | AiAssessmentInstance[]> {
        const assessments$ = this.selectAssessments(asArray);
        return assessments$.pipe(
            map(assessments => {
                if (asArray) {
                    return (assessments as AiAssessmentInstance[]).filter(assessment => assessment.risks?.length);
                }
                const assessmentsWithRisks = new Map<entityId, AiAssessmentInstance>();
                (assessments as Map<entityId, AiAssessmentInstance>).forEach(assessment => {
                    if (assessment.risks?.length) {
                        assessmentsWithRisks.set(assessment.id, assessment);
                    }
                });
                return assessmentsWithRisks;
            })
        );
    }

    selectAssessmentById(id: entityId): Promise<AiAssessmentInstance> {
        return firstValueFrom(this.selectAssessments().pipe(
            map((assessmentMap: Map<entityId, AiAssessmentInstance>) => assessmentMap.get(id)),
        ));
    }

    selectAssessmentByIds(ids: entityId[]): Promise<AiAssessmentInstance[]> {
        const instances: AiAssessmentInstance[] = [];
        return firstValueFrom(this.selectAssessments().pipe(
            map((assessmentMap: Map<entityId, AiAssessmentInstance>) => {
                ids.forEach(id => instances.push(assessmentMap.get(id)));
                return instances;
            }),
        ));
    }

    selectAssessmentByName(name: string): Promise<AiAssessmentInstance> {
        return firstValueFrom(this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => assessments.find(assessment => this.sanitizeAssessmentName(assessment.name) === this.sanitizeAssessmentName(name))),
        ));
    }

    isNameInInventory(name: string): boolean {
        const assessments = (<Array<AiAssessmentInstance>>this.getAssessments(true)());
		return !!assessments?.find(entity => entity.name?.toLowerCase() === name?.toLowerCase());
    }

    sanitizeAssessmentName(name: string): string {
        const decoded = this.sanitizer.sanitize(1, name?.toLowerCase()); // 1 is the value for HTML
        return decoded || '';
    }

    getAssessmentsBySystem(): Signal<Map<entityId, AiAssessmentInstance[]>> {
        return computed(() => {
            const map = new Map<entityId, AiAssessmentInstance[]>();
            const assessments = this.store.signal('assessments')();
            assessments?.forEach(assessment => {
                const systems = assessment.relatedEntities?.filter(entity => entity.type === AiAssessmentEntityTypeEnum.System);
                systems?.forEach(system => {
                    if (map.has(system.id)) {
                        map.set(system.id, map.get(system.id).concat(assessment));
                    }
                    else {
                        map.set(system.id, [assessment]);
                    }
                });
            });
            return map;
        });
    }

    getAssessmentsBySystemId(systemId: string): Signal<AiAssessmentInstance[]> {
        return computed(() => {
            const assessmentsBySystem = this.getAssessmentsBySystem()();
            return assessmentsBySystem.get(systemId) || [];
        });
    }
        
    selectCountByStatus(status?: AiAssessmentStatusEnum): Observable<number> {
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => {
                switch (status) {
                    case AiAssessmentStatusEnum.Draft:
                        return assessments.filter(assessment => assessment.state === AiAssessmentStatusEnum.Draft).length;
                    case AiAssessmentStatusEnum.Completed:
                        return assessments.filter(assessment => assessment.state === AiAssessmentStatusEnum.Completed).length;
                    default:
                        return assessments.length;
                }
            })
        );
    }

    selectCountByCollabState(state?: AiAssessmentCollaborationState): Observable<number> {
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => {
                switch (state) {
                    case AiAssessmentCollaborationState.Submitted:
                        return assessments.filter(assessment => assessment.collabState === AiAssessmentCollaborationState.Submitted).length;
                    case AiAssessmentCollaborationState.Shared:
                        return assessments.filter(assessment => assessment.collabState === AiAssessmentCollaborationState.Shared).length;
                    default:
                        return assessments.length;
                }
            })
        );
    }

    revokeCollaborator(assessmentId: string, email: string): Observable<void> {
        return this.apiClientAiAssessmentsService.revokeCollaborator(assessmentId, email).pipe(
            tap(instance => this.updateInstanceInStore(instance)),
            map(() => void 0)
        );
    }

    inviteCollaborator(assessmentId: string, email: string, message: string): Observable<void> {
        return this.apiClientAiAssessmentsService.inviteCollaborator(assessmentId, email, message).pipe(
            tap(instance => this.updateInstanceInStore(instance)),
            map(() => void 0)
        );
    }

    selectCountByType(type: string): Observable<number> {
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => assessments.filter(assessment => this.filterAssesmentsByType(assessment.templateType, type))?.length ?? 0)
        );
    }

    private filterAssesmentsByType(assessmentType: string, type: string): boolean {
        const cmsTypes = this.contentPipe.transform('ai-assessments.types');

        return assessmentType === type || cmsTypes[AiAssessmentTemplateEnum[assessmentType]] === type;
    }

    selectCountByReviewDateIsDue(): Observable<number> {
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => assessments.filter(assessment => this.isDateInPastPipe.transform(assessment.reviewDate)).length)
        );
    }

    selectCountByCustomView(customView: TableCustomView): Observable<number> {
        const customViewFilters = customView.filters;
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => this.filterAssessments(assessments, customViewFilters)?.length ?? 0)
        );
	}
        
    selectUniqueDataSources(): Observable<SystemInstance[]> {
        return this.systemsQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => combineLatest([this.systemsQuery.selectAllSystems(), this.selectAssessments(true)])),
            map(([systems, assessments]) => this.getUniqueDataSourcesList(systems, assessments as AiAssessmentInstance[])),
        );
    }

    selectUniqueDataSourcesWithRisks(): Observable<SystemInstance[]> {
        return this.systemsQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => combineLatest([this.systemsQuery.selectAllSystems(), this.selectAssessmentsWithRisks(true)])),
            map(([systems, assessments]) => this.getUniqueDataSourcesList(systems, assessments as AiAssessmentInstance[])),
        );
    }

    selectUniqueAssessmentsTypesWithRisks(): Observable<string[]> {
        return this.selectAssessmentsWithRisks(true).pipe(
            map((assessments: AiAssessmentInstance[]) => {
                const uniqueTypes = new Set<string>();
                assessments.forEach(assessment => {
                    if (assessment.templateType) {
                        uniqueTypes.add(assessment.templateType);
                    }
                });
                return Array.from(uniqueTypes);
            })
        );
    }
        
    private getUniqueDataSourcesList(systems: SystemInstance[], assessments: AiAssessmentInstance[]): SystemInstance[] {
        return assessments.reduce((prev, curr) => {
            const instances = curr.relatedEntities
                ?.filter(entity => entity.type === AiAssessmentEntityTypeEnum.System)
                .map(entity => systems.find(system => system.systemId === entity.id))
                .filter(Boolean);

            return !!instances ? Array.from(new Set([...prev, ...instances])) : prev;
        }, []);
    }

    selectUniqueDataRoles(): Observable<AiAssessmentEntity[]> {
        return this.customValuesQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.selectAssessments(true)),
            map(assessments => this.getUniqueEntityList(AiAssessmentEntityTypeEnum.DataRole, assessments as AiAssessmentInstance[])),
        );
    }

    selectUniqueDataSubjects(): Observable<AiAssessmentEntity[]> {
        return this.customValuesQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.selectAssessments(true)),
            map(assessments => this.getUniqueEntityList(AiAssessmentEntityTypeEnum.DataSubject, assessments as AiAssessmentInstance[])),
        );
    }

    selectUniqueBusinessUnits(): Observable<AiAssessmentEntity[]> {
        return this.customValuesQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.selectAssessments(true)),
            map(assessments => this.getUniqueEntityList(AiAssessmentEntityTypeEnum.BusinessUnit, assessments as AiAssessmentInstance[])),
        );
    }

    selectUniqueBusinessOwners(): Observable<AiAssessmentEntity[]> {
        return this.customValuesQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.selectAssessments(true)),
            map(assessments => this.getUniqueEntityList(AiAssessmentEntityTypeEnum.BusinessOwner, assessments as AiAssessmentInstance[])),
        );
    }

    private getUniqueEntityList(valueType: AiAssessmentEntityTypeEnum, assessments: AiAssessmentInstance[]): AiAssessmentEntity[] {
        const entityMap = new Map<entityId, AiAssessmentEntity>();
    
        assessments.forEach(assessment => {
            const instances = assessment.relatedEntities
                ?.filter(entity => entity.type === valueType)
                .filter(Boolean);
    
            instances?.forEach(entity => {
                if (!entityMap.has(entity.id)) {
                    entityMap.set(entity.id, entity);
                }
            });
        });
    
        return Array.from(entityMap.values());
    }

    searchAssessments(assessments: AiAssessmentInstance[], search: string): AiAssessmentInstance[] {
        if (!search) return assessments;
        return assessments.filter(assessment => assessment.name?.toLowerCase()?.includes(search?.toLowerCase()));
    }
    
    filterAssessments(assessments: AiAssessmentInstance[], filters: FilterCategory[]): AiAssessmentInstance[] {
        if (!filters) return assessments;
        for (let filter of filters) {
            if (filter.options.some(option => option.selected)) {
                if (filter.id === AiAssessmentsFilteringGroupEnum.Type) {
                    assessments = this.filterAssessmentsByType(assessments, filter);
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.Collaborators) {
                    assessments = this.filterAssessmentsByCollabState(assessments, filter);
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.Status) {
                    assessments = this.filterAssessmentsByProperty(assessments, filter, 'state');
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.DataSource) {
                    assessments = this.filterAssessmentsByEntityType(assessments, filter, AiAssessmentEntityTypeEnum.System);
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.ReviewDate) {
                    assessments = (assessments as AiAssessmentInstance[]).filter(assessment => this.isDateInPastPipe.transform(assessment.reviewDate));
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.DataRole) {
                    assessments = this.filterAssessmentsByEntityType(assessments, filter, AiAssessmentEntityTypeEnum.DataRole);
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.DataSubject) {
                    assessments = this.filterAssessmentsByEntityType(assessments, filter, AiAssessmentEntityTypeEnum.DataSubject);
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.BusinessUnit) {
                    assessments = this.filterAssessmentsByEntityType(assessments, filter, AiAssessmentEntityTypeEnum.BusinessUnit);
                }
                if (filter.id === AiAssessmentsFilteringGroupEnum.BusinessOwner) {
                    assessments = this.filterAssessmentsByEntityType(assessments, filter, AiAssessmentEntityTypeEnum.BusinessOwner);
                }
                if (this.devAssessmentTemplateSavedQuestionsFF && !!this.customFieldsQuery.getEntity(filter.id)) {
                    assessments = this.filterAssessmentsByCustomField(assessments, filter);
                }
            }
        }
        return assessments;
    }

    private filterAssessmentsByProperty<T extends keyof AiAssessmentInstance>(assessments: AiAssessmentInstance[], filter: FilterCategory, property: T): AiAssessmentInstance[] {
        return assessments.filter(
            assessment => filter.options
                .filter(option => option.selected)
                .map(option => option.id)
                .includes(assessment[property as string])
        );
    }

    private filterAssessmentsByType(assessments: AiAssessmentInstance[], filter: FilterCategory): AiAssessmentInstance[] {
        const cmsTypes = this.contentPipe.transform('ai-assessments.types');
        const optionsIds = filter.options
            .filter(option => option.selected)
            .map(option => option.id);

        return assessments.filter(
            assessment => optionsIds.includes(assessment.templateType) || optionsIds.includes(cmsTypes[AiAssessmentTemplateEnum[assessment.templateType]])
        );
    }

    private filterAssessmentsByCollabState(assessments: AiAssessmentInstance[], filter: FilterCategory): AiAssessmentInstance[] {
        const optionsIds = filter.options
            .filter(option => option.selected)
            .map(option => option.id);

        return assessments.filter(
            assessment => optionsIds.includes(assessment.collabState)
        );
    }
    
    private filterAssessmentsByEntityType(assessments: AiAssessmentInstance[], filter: FilterCategory, type: AiAssessmentEntityTypeEnum): AiAssessmentInstance[] {
        return (assessments as AiAssessmentInstance[]).filter(
              assessment => filter.options
                .filter(option => option.selected)
                .map(option => option.id)
                .some(item => assessment.relatedEntities.filter(e => e.type === type)?.map(e => e.id)?.includes(item))
            );
    }

    private filterAssessmentsByCustomField(assessments: AiAssessmentInstance[], filter: FilterCategory): AiAssessmentInstance[] {
        return (assessments as AiAssessmentInstance[]).filter(
              assessment => filter.options
                .filter(option => option.selected)
                .map(option => option.id)
                .some(item => this.aiAssessmentsV2ParserService.getInstanceCustomFieldValues(assessment)?.get(filter.id)?.map(value => value?.value)?.includes(item))
            );
    }
    
    sortAssessments(assessments: AiAssessmentInstance[], sort: MineSort): AiAssessmentInstance[] {
        switch (sort.active) {
            case AiAssessmentsColumnEnum.Name:
                return assessments.sort((a, b) => sort.direction === SortDirectionEnum.Asc ?
                    a.name?.localeCompare(b.name) : b.name?.localeCompare(a.name)
                );
        
            case AiAssessmentsColumnEnum.Type:
                return assessments.sort((a, b) => sort.direction === SortDirectionEnum.Asc ?
                    a.templateType?.localeCompare(b.templateType) : b.templateType?.localeCompare(a.templateType)
                );
        
            case AiAssessmentsColumnEnum.Status:
                return assessments.sort((a, b) => sort.direction === SortDirectionEnum.Asc ?
                    this.sortByStatus(a, b) : this.sortByStatus(b, a)
                );
        
            case AiAssessmentsColumnEnum.ReviewDate:
                return assessments.sort((a, b) => sort.direction === SortDirectionEnum.Asc ?
                    this.sortByReviewDate(a, b) : this.sortByReviewDate(b, a)
                );
    
          default:
            return assessments;
        }
    }
    
    private sortByStatus(a: AiAssessmentInstance, b: AiAssessmentInstance): number {
        const aStatus = this.getAssessmentTableStatus(a);
        const bStatus = this.getAssessmentTableStatus(b);

        return `${aStatus.status.value}-${new Date(a.lastModified ?? a.createdAt).getTime()}`
            .localeCompare(`${bStatus.status.value}-${new Date(b.lastModified ?? b.createdAt).getTime()}`);
    }

    private getAssessmentTableStatus(instance: AiAssessmentInstance): AiAssessmentTableStatus {
        const statuses = this.contentPipe.transform('ai-assessments.statuses') as DropdownOption[];

        return {
            date: this.datePipe.transform(instance.lastModified ?? instance.createdAt, 'MMM y'),
            status: statuses.find(status => status.id === instance.state)
        } as AiAssessmentTableStatus;
    }

    private sortByReviewDate(a: AiAssessmentInstance, b: AiAssessmentInstance): number {
        return new Date(a.reviewDate || '0').valueOf() - new Date(b.reviewDate || '0').valueOf();
    }

    getMineOSTemplates(): AiAssessmentsMineOSTemplate[] {
        return this.FORM_TEMPLATES;
    }

    getMineOSTemplateByType(type: AiAssessmentTemplateEnum): AiAssessmentsMineOSTemplate {
        return this.FORM_TEMPLATES.find(template => template.type === type);
    }
        
    getMineOSTemplate(type: string, name: string): AiAssessmentsMineOSTemplate {
        return this.FORM_TEMPLATES.find(template => template.name === name && (template.type === type || template.type === AiAssessmentTemplateEnum[type])); 
    }
    
    getTemplates(): Signal<Map<string, AssessmentTemplateResponse>> {
        return this.store.signal('templates');
    }
    
    getTemplate(type: AiAssessmentTemplateEnum, name: string): AssessmentTemplateResponse {
        return Array.from(this.store.get('templates').values()).find(template => template.type === type && name === template.name);
    }

    getTemplateInstance(type: AiAssessmentTemplateEnum, name: string): AiAssessmentsMineOSTemplate | AssessmentTemplateResponse {
        return this.getMineOSTemplate(type, name) ?? this.getTemplate(type, name);
    }

    selectTemplates(): Observable<Map<string, AssessmentTemplateResponse>> {
        return this.store.select('templates');
    }

    selectTemplate(templateId: string): Observable<AssessmentTemplateResponse> {
        return this.selectTemplates().pipe(
            map(templates => templates.get(templateId))
        );
    }

    selectAssessmentTemplate(type: AiAssessmentTemplateEnum, name: string): Observable<AssessmentTemplateResponse | undefined> {
        return this.selectTemplates().pipe(
          map((templates: Map<string, AssessmentTemplateResponse>) => 
            Array.from(templates.values()).find(template => template.type === type && template.name === name)
          )
        );
     }

     selectPaTemplate(): Observable<AssessmentTemplateResponse | undefined> {
        const paTemplateName = this.contentPipe.transform('ai-assessments.mineosTemplates')
          .find(template => template.type === AiAssessmentTemplateEnum.PA)?.name;
      
        return this.selectAssessmentTemplate(AiAssessmentTemplateEnum.PA, paTemplateName);
      }

    selectAssessmentTypes(): Observable<Array<string>> {
        const cmsTypes = this.contentPipe.transform('ai-assessments.types');
        return this.selectAssessments(true).pipe(
            map((assessments: AiAssessmentInstance[]) => Array.from(new Set(assessments.map(assessment => assessment.templateType)))),
            map(types => types.reduce((acc, curr) => {
                if (!acc.some(item => cmsTypes[AiAssessmentTemplateEnum[item]] === cmsTypes[AiAssessmentTemplateEnum[curr]])) {
                    acc.push(curr);
                }
                return acc;
            }, [])),
        );
    }

    getAiAssessmentTemplateEnumKeyByValue(value: AiAssessmentTemplateEnum): string | undefined {
        return Object.keys(AiAssessmentTemplateEnum).find(key => key === value || AiAssessmentTemplateEnum[key] === value);
    }

    // Function to append style to the DOM
    appendTemplateStyleToDOM(style: string): void {
        this.styleElement = document.createElement("style");
        this.styleElement.innerText = style;
        document.body.appendChild(this.styleElement);
    }

    // Function to remove style from the DOM
    removeTemplateStyleFromDOM(): void {
        if (this.styleElement && this.styleElement.parentNode) {
            this.styleElement.parentNode.removeChild(this.styleElement);
        }
    }

    getActions(): RxActions<AiAssessmentActions, {}> {
        return this.store.actions;
    }

    jumpToFormSection(name: string): void {
        this.store.actions.jumpToFormSection(name);
    }

    cloneFormRequest(request: AiAssessmentInstance): void {
        this.store.actions.cloneFormRequest(request);
    }

    submitFormRequest(request: AiAssessmentInstance): void {
        this.store.actions.submitFormRequest(request);
    }

    markInstanceAsApproved(instanceId: string): void {
        this.store.actions.markInstanceAsApproved(instanceId);
    }

    approveInstance(instanceId: string): void {
        this.store.actions.approveInstance(instanceId);
    }
        
    updateInstanceName(instance: AiAssessmentInstance, newName: string): void {
        this.store.actions.updateInstanceName({instance, newName});
    }

    updateInstanceState(state: AiAssessmentActionUpdateState): void {
      this.store.actions.updateInstanceState(state);
    }

    updateInstanceReviewDate(instanceId: string, reviewDate?: string, ownerId?: string): void {
        this.store.actions.updateInstanceReviewDate({instanceId, reviewDate, ownerId});
    }

    deleteFormRequest(id: string): void {
        this.store.actions.deleteInstance(id);
    }

    updateInstanceRisks(risks: Risk[]): void {
        this.store.actions.updateInstanceRisks(risks);
    }

    selectInstance(instanceId: string, lean = false): Observable<AiAssessmentInstance> {
        return this.apiClientAiAssessmentsService.getInstanceById(instanceId, lean).pipe(
            map(instance => this.getDecodedInstance(instance)),
            tap(instance => this.updateInstanceInStore(instance))
        );
    }

    updateInstance(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        if (!request.templateSchema) {
            const defaultTemplate = this.DEFAULT_TEMPLATE;
            request = {
                ...request,
                templateSchema: JSON.stringify(this.getMineOSTemplate(defaultTemplate.type, defaultTemplate.name).template)
            };
        }

        return this.apiClientAiAssessmentsService.updateInstance(request).pipe(
            tap(instance => this.updateInstanceInStore(instance)),
            tap((instance) => {
                if(instance.risks.length) {
                    this.updateInstanceRisks(instance.risks);
                }
            }),
            catchError(error => {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    updateInstanceStatus(instanceId: string, status: AiAssessmentStatusEnum): Observable<AiAssessmentInstance> {
        return this.apiClientAiAssessmentsService.updateInstanceState(instanceId, status);
    }

    updateAssessmentReviewDate(instanceId: string, reviewDate?: string, ownerId?: string): Observable<AiAssessmentInstance> {
        return this.apiClientAiAssessmentsService.updateInstanceReviewDate(instanceId, reviewDate, ownerId).pipe(
            switchMap(() => this.apiClientAiAssessmentsService.getInstanceById(instanceId)),
            tap(instance => this.updateInstanceInStore(instance))
        );
    }
        
    createInstance(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        return this.apiClientAiAssessmentsService.createInstance(request).pipe(
            map(instance => ({...instance, ...request})),
            tap(instance => this.updateInstanceInStore(instance))
        );
    }
        
    deleteInstance(id: string): Observable<void> {
        return this.apiClientAiAssessmentsService.deleteInstance(id).pipe(
            tap(() => this.removeInstanceFromStore(id))
        );
    }

    submitCollaborationForm(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        return this.apiClientAiAssessmentsService.submitInstance(request).pipe(
            map(instance => ({...instance, ...request})),
            tap(instance => this.updateInstanceInStore(instance))
        );
    }

    uploadInstanceAttachment(instanceId: string, file: File): Observable<AiAssessmentAttachment> {
        return this.apiClientAiAssessmentsService.uploadInstanceAttachment(instanceId, file).pipe(
            catchError(error => {
                const message = error?.error?.includes('MaxAttachments') ? 'ai-assessments.attachmentMaxOperationsError' : 'ai-assessments.attachmentUploadError';
                this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform(message));
                this.loggerService.error(this.loggerName, `Upload attachment failed: ${error.error}`);
                console.error(error);
                throwError(() => error);
                return EMPTY;
            }),
        );
    }

    getInstanceAttachmentUrl(instanceId: string, attachmentId: string): Observable<string> {
        return this.apiClientAiAssessmentsService.getInstanceAttachmentUrl(instanceId, attachmentId).pipe(
            catchError(error => {
                this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('ai-assessments.attachmentDownloadError'));
                this.loggerService.error(this.loggerName, `Download attachment failed: ${error.error}`);
                console.error(error);
                return EMPTY;
            }),
        );
    }

    deleteInstanceAttachment(instanceId: string, attachmentId: string): Observable<AiAssessmentAttachment> {
        return this.apiClientAiAssessmentsService.deleteInstanceAttachment(instanceId, attachmentId).pipe(
            catchError(error => {
                this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('ai-assessments.attachmentDeleteError'));
                this.loggerService.error(this.loggerName, `Delete attachment failed: ${error.error}`);
                console.error(error);
                return EMPTY;
            }),
        );
    }

    getAiAssessmentNewInstancePayload(name: string, template: DropdownOption, relatedEntities: AiAssessmentEntity[] = []): AiAssessmentInstance {
        const templateInstance = this.getTemplateInstance(template.id as AiAssessmentTemplateEnum, template.value);
        const templateType = this.getAiAssessmentTemplateEnumKeyByValue(templateInstance.type as AiAssessmentTemplateEnum);
        const schema = Object.keys(templateInstance).includes('template') ?
          JSON.stringify((<AiAssessmentsMineOSTemplate>templateInstance).template) :
          (<AssessmentTemplateResponse>templateInstance).schema;
    
        return {
          name,
          state: AiAssessmentStatusEnum.Draft,
          agentId: this.profileQuery.getValue().userId,
          createdAt: new Date().toISOString(),
          templateId: templateType,
          templateType,
          templateSchema: schema,
          data: JSON.stringify({}),
          relatedEntities,
        } as AiAssessmentInstance;
    }

    private getDecodedInstance(instance: AiAssessmentInstance): AiAssessmentInstance {
        if (instance?.data) {
            return { 
                ...instance, 
                name: this.htmlDecodePipe.transform(instance.name),
                data: this.htmlDecodePipe.transform(instance.data) 
            } as AiAssessmentInstance;
        }
        return instance;
    }
        
    private updateInstanceInStore(instance: AiAssessmentInstance): void {
        const assessments = this.store.get('assessments') ?? new Map<string, AiAssessmentInstance>();
        assessments.set(instance.id, instance);
        this.store.set('assessments', state => new Map(assessments.entries()));
    }
        
    private removeInstanceFromStore(id: string): void {
        this.store.get('assessments').delete(id);
    }

    buildSchemaBySections(sections: AiAssessmentsFormControl[]): string {
        const schemaArr = [];
        const conditionalLogicFF = this.featureFlagQuery.getFlag(FeatureFlags.DevAssessmentsConditionalQuestions);
        
        if (!sections?.length) return JSON.stringify(schemaArr);
        sections.forEach(section => {
            schemaArr.push({
                ...section,
                controls: section.controls,
                type: section.type === AiAssessmentsFormControlTypeEnum.NewSection ? AiAssessmentsFormControlTypeEnum.Group : section.type
            });
        });

        return JSON.stringify(conditionalLogicFF ? this.assessmentsLogicManagerService.generateSectionsUuid(schemaArr) : schemaArr);
    }

    createTemplate(request: AssessmentTemplateRequest): Observable<AssessmentTemplateResponse> {
        return this.apiClientAiAssessmentsService.createTemplate(request).pipe(
            tap(template => this.updateTemplateInStore(template)),
        );
    }

    updateTemplate(request: AssessmentTemplateResponse): Observable<void> {
        return this.apiClientAiAssessmentsService.updateTemplate(request).pipe(
            tap(() => this.updateTemplateInStore(request)),
        );
    }

    deleteTemplate(templateId: string): Observable<void> {
        return this.apiClientAiAssessmentsService.deleteTemplate(templateId).pipe(
            tap(() => this.removeTemplateFromStore(templateId))
        );
    }

    private updateTemplateInStore(template: AssessmentTemplateResponse): void {
        const templates = this.store.get('templates');
        templates.set(template.id, template);
        this.store.set('templates', state => new Map(templates.entries()));
    }

    private removeTemplateFromStore(id: string): void {
        this.store.get('templates').delete(id);
    }

    getUniqueControlNameSet(controls: Array<AiAssessmentsFormControl>): Set<string> {
        const set = new Set<string>();
        for (let control of controls) {
          set.add(control.label?.toLowerCase());
        }
        return set;
    }

    generateNewSection(name?: string, ordinal?: number): AiAssessmentsFormControl {
        const defaultSectionLabel = this.contentPipe.transform('ai-assessments.defaultSectionLabel');
        const conditionalLogicFF = this.featureFlagQuery.getFlag(FeatureFlags.DevAssessmentsConditionalQuestions);

        const section = {
            type: AiAssessmentsFormControlTypeEnum.NewSection,
            name: !!name ? this.camelCaseWithSpacesPipe.transform(name) : defaultSectionLabel,
            label: name ?? defaultSectionLabel,
            controls: []
        } as AiAssessmentsFormControl;

        if (conditionalLogicFF) {
            section.id = this.assessmentsLogicManagerService.generateSectionUuid(section, ordinal ?? 0);
        }

        return section;
    }

    validateControlLabel(control: AiAssessmentsFormControl): boolean {
        return !control.label?.trim();
    }

    isNewSectionIsEmpty(section: AiAssessmentsFormControl): boolean {
        if (section.type === AiAssessmentsFormControlTypeEnum.NewSection) {
            return !section.controls?.length;
        }
        return false;
    }

    isGroupSectionIsEmpty(section: AiAssessmentsFormControl): boolean {
        if (section.type === AiAssessmentsFormControlTypeEnum.Group) {
            return !section.controls?.length;
        }
        return false;
    }

    hasDropdownsWithoutOptions(sections: AiAssessmentsFormControl[]): boolean {
        const condition: StatefulConditionFunction = (control, state) => {
            return [!control.options?.length, state];
        };
        return this.validateSections(sections, condition, null, AiAssessmentsFormControlTypeEnum.Dropdown);
    }

    hasDropdownsWithEmptyOption(sections: AiAssessmentsFormControl[]): boolean {
        const condition: StatefulConditionFunction = (control, state) => {
            return [control.options.some(option => !option.value?.trim()), state];
        };
        return this.validateSections(sections, condition, null, AiAssessmentsFormControlTypeEnum.Dropdown);
    }

    hasMultipleCustomComponentInstances(sections: AiAssessmentsFormControl[]): boolean {
        const condition: StatefulConditionFunction = (control, state) => {
            if (!state) state = {};
            if (state[control.name]) {
                return [true, state];
            } else {
                state[control.name] = true;
                return [false, state];
            }
        };
        return this.validateSections(sections, condition, {}, AiAssessmentsFormControlTypeEnum.CustomComponent);
    }

    private validateSections(sections: AiAssessmentsFormControl[], condition: StatefulConditionFunction, initialState: any, type: AiAssessmentsFormControlTypeEnum): boolean {
        let state = initialState;
        for (let section of sections) {
            if (section.controls?.length) {
                for (let control of section.controls) {
                    if (control.type === type) {
                        const [result, newState] = condition(control, state);
                        if (result) {
                            window.scrollTo({
                                top: document.documentElement.scrollHeight,
                                behavior: 'smooth'
                            });
                            return true;
                        }
                        state = newState;
                    }
                }
            }
        }
        return false;
    }
    
    replaceTemplateType(assessment: AiAssessmentPageRequest): AiAssessmentPageRequest {
        const { type } = assessment;
        const cmsTypes = this.contentPipe.transform('ai-assessments.types');
        return { ...assessment, type: cmsTypes[AiAssessmentTemplateEnum[type]] ?? cmsTypes[AiAssessmentTemplateEnum.Empty] };
    }

    async buildInstancesForExport(assessments: AiAssessmentInstance[], ignoreIncompleteFields: boolean): Promise<AiAssessmentExportTemplateInstance[]> {
        const ownerIds = assessments.map(({ ownerId }) => ownerId).filter(Boolean);
        const parsedAssessments = assessments.map(assessment => this.aiAssessmentsV2ParserService.parseInstanceToPageRequestV2(assessment));
        const employees = await this.aiAssessmentsExportHelper.getBusinessOwnerData(parsedAssessments, ownerIds);

        this.loggerService.info(this.loggerName, `Fetched data for "${employees?.length ?? '0'}" employees.`);
        return Promise.all(parsedAssessments.map(instance => this.aiAssessmentsExportHelper.buildInstanceForExport(
            this.replaceTemplateType(instance),
            employees,
            ignoreIncompleteFields,
        )));
    }

    private collectInstanceImages(instances: AiAssessmentExportTemplateInstance[]): Observable<AiAssessmentExportTemplateInstance[]> {
        const instancesWithoutImages = [...instances];
        const imagesByUrl$ = from(this.aiAssessmentsExportHelper.extractImagesInBase64(instances));
        const companyIconObject$ = this.profileQuery.selectCompanyIcon().pipe(
            switchMap((companyIcon) => combineLatest([this.aiAssessmentsExportHelper.imageToBase64(companyIcon), of(companyIcon)])),
            map(([base64, companyIcon]) => {
                return { [companyIcon]: base64 };
            }),
        );
        return combineLatest([companyIconObject$, imagesByUrl$]).pipe(
            map(([companyIconObject, otherImagesObject]) => {
                const imagesByUrl = { ...companyIconObject, ...otherImagesObject };
                instancesWithoutImages[0].images = imagesByUrl;
                this.loggerService.info(this.loggerName, `${Object.keys(imagesByUrl).length} images converted to base64.`);
                return instancesWithoutImages;
            }),
        );
    }

    exportInstances(assessments: AiAssessmentInstance[], metaData: Partial<AiAssessmentExportMetadata>): Observable<ArrayBuffer> {
        this.loggerService.info(this.loggerName, `Starting PDF export: exporting ${assessments?.length ?? '0'} instances...`);
        
        const instances$ = from(this.buildInstancesForExport(assessments, metaData.ignoreIncompleteFields));
        const ropaDetails$ = this.companySettingsQuery.selectRopaDetails();
        const fullName$ = this.profileQuery.selectFullname();

        const instancesWithImages$ = instances$.pipe(
            switchMap(instances => this.collectInstanceImages(instances)),
        );

        this.dialogRef = this.dialogService.openDialogCommon(MineAsyncOperationSpinnerDialogComponent, this.contentPipe.transform('pdf-generator.generatingPdfReportTitle'), '442px', false);

        return combineLatest([instancesWithImages$, ropaDetails$, fullName$]).pipe(
            switchMap(([instances, ropaDetails, agentName]) => this.pdfGeneratorService.sendRequest({
              exportType: ExportTypeEnum.Assessment,
              squidexContents: [ContentSchemaHelper.PdfGenerator],
              data: {
                instances,
                metaData: {
                    ...metaData,
                    ropaDetails,
                    agentName,
                },
              }
            })),
            catchError(error => {
                this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('pdf-generator.exportError'));
                this.loggerService.error(this.loggerName, `PDF export failed: ${error.message}`);
                console.error(error);
                return EMPTY;
            }),
            finalize(() => this.dialogRef?.close()),
        );
    }

    moveControlTypeToGroup(control: AiAssessmentsFormControl): AiAssessmentsFormControl {
        return { 
            ...control, 
            type: AiAssessmentsFormControlTypeEnum.Group, 
            controls: [control].map(({ controls, ...control }) => ({ ...control })) 
        } as AiAssessmentsFormControl;
    }

    decodeTemplateSchema(template: AssessmentTemplateResponse) {
        if (!!template.schema) {
            const decodedSchema = he.decode(template.schema);
            let schema = JSON.parse(decodedSchema);
            if (schema && this.devAssessmentsApiV2FF) {
                schema = schema.map(section => this.aiAssessmentsV2ParserService.parseSectionControls(section));
            }
            if (schema && this.devAssessmentTemplateSavedQuestionsFF) {
                schema = this.updateSectionsWithCustomFields(schema);
            }
            return schema ?? [];
        }
        return [];
    }

    private updateSectionsWithCustomFields(sections: AiAssessmentsFormControl[]): AiAssessmentsFormControl[] {
        const updatedSections: AiAssessmentsFormControl[] = [];
        sections.forEach(section => {
          const updatedSection: AiAssessmentsFormControl = { ...section, controls: [] };
          for (let control of section.controls) {
            if (control.customFieldId) {
              const customField = this.customFieldsQuery.getEntity(control.customFieldId);
              if (customField) {
                this.alignControlWithCustomField(control, customField);        
              }
              else {
                continue;
              }
            }
            updatedSection.controls.push(control);
          }
          updatedSections.push(updatedSection);
        });
        return updatedSections;
    }

    private alignControlWithCustomField(control: AiAssessmentsFormControl, customField: CustomFieldV2): void {
        control.label = customField.name;
        control.multiple = customField.inputData?.useMultipleOptions ?? false;
        control.options = this.getOptionsForNewDropdownControl(customField.inputData?.inputOptions);
    }

    getOptionsForNewDropdownControl(inputOptions?: CustomFieldOption[]): DropdownOption[] {
        const defaultOption = [{ id: `o-${this.randomUUIDPipe.transform()}`, value: null } as DropdownOption];
        return (inputOptions?.length > 0 ? inputOptions.map(o => ({ id: o.id, value: o.name, chipColor: {backgroundColor: o.color, textColor: '--mine-blue-dark'}} as DropdownOption)) : defaultOption);
    }

    private setFunctions(res: string[]): void {
		this.functions.next(res?.sort((a, b) => {
			// make sure cross functional function will be the latest
			if (a?.toLowerCase() === this.CROSS_FUNCTIONAL_FUNCTION_NAME.toLowerCase()) {
				return 1;
			}
			else if (b?.toLowerCase() === this.CROSS_FUNCTIONAL_FUNCTION_NAME.toLowerCase()) {
				return -1;
			}
			return a?.toLowerCase().localeCompare(b?.toLowerCase());
		}));
	}

    getFunctions(): string[] {
		return this.functions.value;
	}

    getLockStatus(instanceId: string): Observable<AiAssessmentLockStatus> {
        return this.apiClientAiAssessmentsService.getLockStatus(instanceId);
    }

    setLockStatus(instanceId: string, isLocked: boolean): Observable<AiAssessmentLockStatus> {
        return this.apiClientAiAssessmentsService.setLockStatus(instanceId, isLocked)
            .pipe(
                tap(res => this.aiAssessmentsLockStatusService.updateLockStatus(res))
            );
    }
}