import { Injectable } from "@angular/core";

import { AiAssessmentUuidPipe } from "../pipes/ai-assessment-uuid.pipe";
import { AiAssessmentsFormControl } from "../models/ai-assessments.interface";

@Injectable({
    providedIn: 'root'
})
export class AssessmentsLogicManagerService {
    aiAssessmentsUuidPipe = new AiAssessmentUuidPipe();

    generateControlUuid(control: AiAssessmentsFormControl, ordinal: number = 0): string {
        return this.aiAssessmentsUuidPipe.transform(`${control.type}-${control.name}-${ordinal}`);
    }

    generateSectionUuid(section: AiAssessmentsFormControl, ordinal: number = 0): string {
        return this.aiAssessmentsUuidPipe.transform(`${section.type}-${section.name}-${ordinal}`);
    }

    generateSectionsUuid(sections: AiAssessmentsFormControl[]): AiAssessmentsFormControl[] {
        return sections.map((section, sIndex) => {
            return {
                ...section,
                id: section.id ?? this.generateSectionUuid(section, sIndex),
                controls: section.controls.map((control, cIndex) => {
                    return {
                        ...control,
                        id: control.id ?? this.generateControlUuid(control, cIndex),
                    };
                })
            };
        });
    }
}